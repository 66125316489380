import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { useAuth } from '../hooks/useAuth';

export default function RequireAuth({ children }) {
  let { isAuthenticated } = useAuth();
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace/>;
  }

  return children;
}
