import React, {useCallback, useEffect, useState} from 'react';
import UsersTable from "../components/UsersTable";
import * as API from "../utils/api";

const UsersContainer = () => {
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 20,
        rows: [],
        loading: true,
        rowCount: 0,
        filterValue:null,
        sortValue:null
    });
    const mapDataForTable = ({count = 0, users = []}) => {
        setRowsState((prev) => ({...prev, rowCount: count, rows: users, loading: false}))
    }
    const getUsers = useCallback((page, pageSize,filterValue, sortValue) => {
        return API.fetchUsers(mapDataForTable, page, pageSize,filterValue,sortValue)
    }, []);

    useEffect(() => {
        getUsers(rowsState.page, rowsState.pageSize, rowsState.filterValue,rowsState.sortValue );
    }, [rowsState.page,rowsState.filterValue, rowsState.sortValue, rowsState.pageSize, getUsers]);

    return (
        <UsersTable rowsState={rowsState} setRowsState={setRowsState}/>
    );
};

export default UsersContainer;
