import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AddCurrencyCardForm from '../components/AddCurrencyCardForm';
import ButtonGroup from '@mui/material/ButtonGroup';
import * as API from "../utils/api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};


const CurrencyCardsContainer = () => {
    const [card, setCard] = useState(null);
    const [addCardModalOpen, setAddCardModal] = useState(false);

    const buttons = [
        // <Button key="one">One</Button>,
        <Button key="three" onClick={() => setAddCardModal(true)}>+ Add Card</Button>,
    ];

    return (
        <Container sx={{
            padding: '20px',
        }}>
            <ButtonGroup size="large" aria-label="large button group">
                {buttons}
            </ButtonGroup>
            <Modal
                open={addCardModalOpen}
                onClose={() => setAddCardModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AddCurrencyCardForm />
                </Box>
            </Modal>
        </Container>

    );
};

export default CurrencyCardsContainer;
