import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import formConfig from '../utils/config';
import { Tooltip } from '@mui/material';

const createFormSection = (config, control, sectionId) => Object.entries(config)
  .map(([attributeId, {
    label,
    type,
    helperText,
    validation,
    units,
    section
  }]) => {
    return sectionId === section && <Controller
      key={attributeId}
      name={attributeId}
      control={control}
      render={({ field }) =>
        <Tooltip title={helperText} placement="top">
          <TextField
            label={label}
            type={type}
            // helperText={helperText}
            id="outlined-start-adornment"
            sx={{ m: 2 }}
            InputProps={{
              inputProps: validation,
              startAdornment: <InputAdornment position="start">{units}</InputAdornment>,
              endAdornment: <InputAdornment position="end">min:{validation.min} -
                max:{validation.max}</InputAdornment>
            }}
            {...field}
          />
        </Tooltip>
      }
    />;
  });

const FormSectionFactory = ({
  sections,
  control
}) => sections.map((section) => {
  return (<div key={section} style={{
    display: 'flex',
    flexDirection: 'column',
    width: 'auto'
  }}>
    <Typography variant="h6" component="h2" align="center">
      {section}
    </Typography>
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      {createFormSection(formConfig, control, section)}
    </div>
  </div>);
});

export default FormSectionFactory;
