import React from 'react';
import SignIn from "../components/SignIn";

const LoginContainer = () => {
    return (
        <SignIn />
    );
};

export default LoginContainer;
