import React from 'react';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import * as API from '../utils/api';
import FormSectionFactory from '../components/FormSectionFactory';

import formConfig from '../utils/config';

function Settings({
  settings: {
    breeding,
    upgrade,
    market,
  },
}) {

  const { handleSubmit, control } = useForm({
    defaultValues: { ...breeding, ...upgrade, ...market },
  });

  const onSubmit = (data) => {
    const params = Object.keys(data)
      .reduce((acc, el) => (formConfig[el] ? {
        ...acc,
        [formConfig[el].section]: {
          ...acc[formConfig[el].section],
          [el]: data[el],
        },
      } : acc), {});
    API.updateSettings(console.log, params);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
        >
          <FormSectionFactory control={control} sections={['breeding', 'upgrade', 'market']}/>
          <Button
            style={{
              position: 'fixed',
              bottom: '10px',
            }}
            type="submit"
            variant="contained"
          >
            save
          </Button>
        </div>
      </form>
    </Box>
  );
}

export default Settings;
