import './App.css';
import MainContainer from './containers/MainContainer';
import {
  Routes,
  Route,
} from 'react-router-dom';
import LoginContainer from './containers/Login';
import React from 'react';
import RequireAuth from './components/requireAuth'


const App = () =>
  (
    <Routes>
      <Route exact path="/login" element={<LoginContainer/>}/>
      <Route
        path="*"
        element={
          <RequireAuth>
            <MainContainer/>
          </RequireAuth>
        }
      />

    </Routes>
  );

export default App;
