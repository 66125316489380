import React, { useCallback, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Settings from './Settings';
import NavBar from '../components/NavBar';
import * as API from '../utils/api';
import Users from "./Users";
import CurrencyCards from "./CurrencyCards";

function MainContainer() {
  const [settings, setSettings] = useState(null);

  const getSettings = useCallback(() => API.fetchSettings(setSettings), [API]);
  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <NavBar />
      <Routes>
        <Route
          path="/settings"
          element={settings ? <Settings settings={settings} /> : <>loading</>}
        />
        <Route
          path="/users"
          element={<Users />}
        />
        <Route
          path="/currency-cards"
          element={<CurrencyCards />}
        />
      </Routes>
    </>
  );
}

export default MainContainer;
