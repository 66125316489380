import { useNavigate } from 'react-router-dom';

export function useAuth() {
  let navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('Bearer');
  const signIn = (token, callback) => {
    localStorage.setItem('Bearer', token);
    return navigate('/settings', { replace: true });
  };
  const signOut = () => {
    localStorage.removeItem('Bearer');
    return navigate('/login', { replace: true });
  };
  return {
    isAuthenticated,
    signIn,
    signOut
  };
}
