import React, {useCallback} from 'react';
import {
    DataGrid,
    getGridStringOperators,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    HideGridColMenuItem,
    GridColumnsMenuItem
} from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import {changeStatusUser} from "../utils/api";

const columns = [
    {
        field: 'wallet',
        filterOperators: getGridStringOperators().filter(({value}) => value === 'contains' || value === 'equals'),
        headerName: 'wallet address',
        width: 150
    },
    {field: 'id', headerName: 'user ID', width: 130},
    {field: 'IGMBalance', headerName: 'IGM Balance',filterable:false, width: 200},
    {field: 'comment', headerName: 'comment', width: 200},
    {
        field: 'status', headerName: 'status', type: 'singleSelect', preProcessEditCellProps: async (params) => {
            const value = !params.props.updated ? await changeStatusUser(console.log, params.id, params?.props?.value) : params.props.value
            const hasError = params?.props?.value?.length < 3;
            return {value, error: hasError, updated: '1'};
        }, valueOptions: ['block', 'active'], width: 200, editable: true
    },
    {
        field: 'createdAt',
        filterable:false,
        headerName: 'registration date',
        type: 'date',
        width: 100,
    }
];

const UsersTable = ({rowsState, setRowsState}) => {
    const onFilterChange = useCallback((filterModel) => {
        setRowsState((prev) => ({...prev, filterValue: filterModel.items[0]}))
    }, [setRowsState]);

    const onSortChange = useCallback((sortModel) => {
        setRowsState((prev) => ({...prev, sortValue: sortModel[0]}))
    }, [setRowsState]);

    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        }}
        >
            <div style={{height: '90vh', width: '100%', padding: '20px'}}>
                <DataGrid
                    columns={columns}
                    filterMode="server"
                    editMode='row'
                    onFilterModelChange={onFilterChange}
                    onSortModelChange={onSortChange}
                    components={{
                        ColumnMenu: GridColumnMenu,
                    }}
                    hideFooterSelectedRowCount={true}
                    pagination
                    {...rowsState}
                    paginationMode="server"
                    onPageChange={(page) => setRowsState((prev) => ({...prev, page}))}
                    onPageSizeChange={(pageSize) =>
                        setRowsState((prev) => ({...prev, pageSize}))
                    }
                />
            </div>
        </Box>
    );
};

const GridColumnMenu = function (props, ref) {
    const {hideMenu, currentColumn} = props;

    return (
        <GridColumnMenuContainer {...props}>
            <GridFilterMenuItem onClick={hideMenu} column={currentColumn}/>
            <HideGridColMenuItem onClick={hideMenu} column={currentColumn}/>
            <GridColumnsMenuItem onClick={hideMenu} column={currentColumn}/>
        </GridColumnMenuContainer>
    );
};
export default UsersTable;
