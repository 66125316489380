module.exports = {
  costPerCryptoshoppieIGM: {
    section: 'breeding',
    label: 'Breeding cost (per cryptoshoppie) IGM',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all cryptoshoppies',
    units: 'IGM',
    validation: { min: 50, max: 100 },
  },
  costPerCryptoshoppieSUB: {
    section: 'breeding',
    label: 'Breeding cost (per cryptoshoppie) SUB',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all cryptoshoppies',
    units: 'SUB',
    validation: { min: 0, max: 10 },
  },
  multiplierCoefficient: {
    section: 'breeding',
    label: 'Breeding cost multiplier coefficient',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all cryptoshoppies',
    units: 'units',
    validation: { min: 0, max: 1, step: 0.05 },
  },
  verticalMutationProbability: {
    section: 'breeding',
    label: 'Vertical mutation probability',
    type: 'number',
    componentType: 'input',
    helperText: 'Mutation between levels. Applies to all cryptoshoppies',
    units: '%',
    validation: { min: 1, max: 10, step: 0.5 },
  },
  horizontalMutationProbability: {
    section: 'breeding',
    label: 'Horizontal mutation probability',
    type: 'number',
    componentType: 'input',
    helperText: 'Mutation within a level. Applies to all cryptoshoppies',
    units: '%',
    validation: { min: 1, max: 20 },
  },
  breedingDelay: {
    section: 'breeding',
    label: 'Breeding delay',
    type: 'number',
    componentType: 'input',
    helperText: 'Minimum time in between breedings. Applies to all cryptoshoppies',
    units: 'hours',
    validation: { min: 1, max: 24 },
  },
  breedingDelayMultiplier: {
    section: 'breeding',
    label: 'Breeding delay multiplier coefficient',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to each subsequent breeding for a particular cryptoshoppie',
    units: 'units',
    validation: { min: 0, max: 2, step: 0.05 },
  },
  maturityPeriod: {
    section: 'breeding',
    label: 'Maturity period',
    type: 'number',
    componentType: 'input',
    helperText: 'Minimum time between the moment cryptoshoppie is born and its first breeding',
    units: 'hours',
    validation: { min: 0, max: 48 },
  },
  advancedUpgradeCost: {
    section: 'upgrade',
    label: 'Advanced Upgrade cost',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all cryptoshoppies. Possible 1 upgrade per character.',
    units: 'SUB',
    validation: { min: 10, max: 200 },
  },
  basicUpgradeCost: {
    section: 'upgrade',
    label: 'Basic Upgrade cost',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all cryptoshoppies.',
    units: 'IGM',
    validation: { min: 10, max: 100 },
  },
  upgradeCostMultiplierCoefficient: {
    section: 'upgrade',
    label: 'Upgrade cost multiplier coefficient',
    type: 'number',
    componentType: 'input',
    helperText: 'Upgrade cost multiplier= 1+n*0,25',
    units: 'units',
    validation: { min: 0, max: 0.5, step: 0.01 },
  },
  exchangeFeeSUBToIGM: {
    section: 'market',
    label: 'Exchange fee SUB ↔︎ IGM',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all transactions SUB',
    units: '%',
    validation: { min: 1, max: 5 },
  },
  exchangeFeeSUBToUNI: {
    section: 'market',
    label: 'Exchange fee SUB ↔︎ UNI',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all transactions UNI',
    units: '%',
    validation: { min: 1, max: 5 },
  },
  systemCommissionCommunityBreeding: {
    section: 'market',
    label: 'System commission (community breeding)',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all transactions',
    units: '%',
    validation: { min: 1, max: 10 },
  },
  systemCommissionSale: {
    section: 'market',
    label: 'System commission (sale)',
    type: 'number',
    componentType: 'input',
    helperText: 'Applies to all transactions',
    units: '%',
    validation: { min: 1, max: 10 },
  },
};
