import React, { useState } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import QRCode from 'react-qr-code';
import API from "../utils/api";
import { UI_URL } from '../constants';

const AddCurrencyCard = () => {
    const [data, setData] = useState({
        balance: 10,
        partner: 'atb',
        phenotype: 1
    });

    const [createdCard, setCreatedCard] = useState(null);

    const [isWithPhenotype, setIsWithPhenotype] = useState(false);

    const partners = [
        {
            value: 'atb',
            label: 'ATB',
        },
    ];

    const formHandler = (field) => (event) => setData({ ...data, [field]: event.target.value });
    const getQRValue = () => {
        return `${UI_URL}/?code=${createdCard.encrypted}`;
    }
    const sendData = async () => {
    


        const payload = {
            ...data,
            phenotype: isWithPhenotype ? data.phenotype : null
        }

        const { data: card } = await API.post('/api/currency-cards', payload);

        setCreatedCard(card);
    }
    return (
        <Container sx={{
            padding: '20px',
        }}>
            {createdCard
                ? (
                    <Box>
                        <Box>
                            <QRCode value={getQRValue()} />
                        </Box>
                        <Button variant="contained" onClick={() => setCreatedCard(null)}>Create one more card</Button>
                    </Box>

                )
                : (
                    <Box>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add Currency Card
                        </Typography>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >

                            <TextField id="outlined-basic" label="Balance" variant="outlined" value={data.balance}
                                onChange={formHandler('balance')} />
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Select Partner"
                                value={data.partner}
                                onChange={formHandler('partner')}
                            >
                                {partners.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Box>
                                <Switch
                                    checked={isWithPhenotype}
                                    onChange={() => setIsWithPhenotype(!isWithPhenotype)}
                                    name="loading"
                                    color="primary"
                                />
                            </Box>
                            {
                                isWithPhenotype && (
                                    <Box sx={{
                                        display: 'grid',
                                        gridTemplateColumns: { sm: '1fr 1fr' },
                                        gap: 2,
                                    }}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Phenotype ID"
                                            variant="outlined"
                                            value={data.phenotype}
                                            onChange={formHandler('phenotype')}
                                        />
                                    </Box>
                                )
                            }
                            <Button variant="contained" onClick={sendData}>Create Card</Button>

                        </Box>

                    </Box>
                )}

        </Container>

    );
};

export default AddCurrencyCard;
