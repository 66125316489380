import axios from 'axios';

const Axios = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000' });
const token = localStorage.getItem('Bearer');
Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
export default Axios;
export const login = (props) =>
  Axios.post(`/api/auth/admin/login`, props);
export const fetchSettings = async (callback) => {
  const result = await Axios.get('/api/settings');
  callback(result.data);
};
export const changeStatusUser = async (callback, id, status) => {
  const result = await Axios.patch('/api/users/change-status',{id,status});
  // callback(result.data);
  return result.data.status
};
export const fetchUsers = async (callback,page,pageSize,filterValue,sortValue) => {
  // await Axios.post('/api/users/create');
  const sort = sortValue ? `&sortedField=${sortValue.field}&sort=${sortValue.sort}`:''
  const search = filterValue?.value ? `&filteredField=${filterValue.columnField}&match=${filterValue.value}` : ''
  const result = await Axios.get(`/api/users?${sort}&offset=${page*pageSize}&limit=${pageSize}${search}`);
  callback(result.data);
};
export const updateSettings = async (callback, params) => {
  const result = await Axios.patch('/api/settings', params);
  callback(result.data);
};
